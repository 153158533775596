* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

html ::-webkit-scrollbar {
    width: 6px;

}

html ::-webkit-scrollbar-thumb {
    background-color: #777c98;
    border-radius: 12px;
    border: 1px solid transparent;
    background-clip: content-box;
}

html ::-webkit-scrollbar-track {
    background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input, button, textarea {
    outline: none;
}

.custom-layout .react-colorful__hue {
    order: 1;
    margin: 12px 0;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
    height: 12px;
    border-radius: 15px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer,
.custom-layout .react-colorful__saturation-pointer {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

:root {
    --epr-emoji-fullsize: 30px !important;
}

.EmojiPickerReact {
    --epr-emoji-fullsize: 52px !important;
    --epr-emoji-padding: 16px !important;
    --epr-category-padding: 0 !important;
    --epr-header-padding: 0 0 15px 0 !important;
    padding: 20px;
}

.epr-body {
    overflow-y: scroll;
    max-height: 200px;
    background: #ECEDF2;
    padding: 12px;
    border-radius: 12px;
}

.epr-emoji-category-content {
    grid-gap: 12px !important;

    button {
        background: white;
        border: 1px solid #D5D7E2;

    }
}

.epr-category-nav, .epr-emoji-category-label {
    display: none !important;
}